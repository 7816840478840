<template>
  <div id="CompanyIntroduction">
    <div class="banner container-fuild text-center">
      <p class="wow slideInLeft">关于我们</p>
    </div>

    <div class="container">
      <div class="nav container text-center">
        <a
          href="#"
          :class="{'active':tabArea}"
          @click="tabArea=true"
        >公司简介</a>
        <a
          href="#"
          :class="{'active':!tabArea}"
          @click="tabArea=false"
        >发展历程</a>
      </div>
    </div>
    <!--        公司简介-->
    <div
      v-show="tabArea"
      class="container"
    >
      <!--            介绍            -->
      <h1
        class="text-center container"
        style="margin: 20px 0;"
      >我们是工匠，我们是极客</h1>
      <p>
        上海来店信息技术有限公司，由连锁行业资深团队创立，为连锁企业提供“人·货·场”中与“场”相关的整体解决方案。
      </p>
      <p>
        ”店驰“APP和云服务平台是来店基于大数据与人工智能的主打产品。门店是”场“的载体，”店驰“围绕门店，提供开店前的网络规划、店铺跟踪、选址评估等服务；开店中的设计、工程、采购、验收、合同、采购等服务；以及开店后的运营、暂闭、装修升级、关店、续店等技术服务。
      </p>
      <p>
        来店人的使命是：让零售人牛起来！来店人的价值观是：客户 · 团队 · 专业 · 勤奋 ·
        靠谱！我们努力将领先的门店全生命周期相关的知识、技能、经验、流程沉淀到产品与服务中，让用户直接享受到来店人的努力成果。
      </p>
      <p>
        经过三年多的发展，我们在商圈与店铺大数据、企业最佳网规与选址模型、店铺全生命周期管理等方面，构建了独特的核心竞争力。
      </p>
      <p>
        包括上市企业来伊份，红杉投资的见福、TODAY、鲜丰水果、生鲜传奇，京东投资的钱大妈、新佳宜，今日与源码投资的邻几，以及永辉、三江、三津、快鱼等60多家区域头部连锁企业与来店展开了长期合作。
      </p>
      <p>
        来店人将齐心协力，不辱使命！开好店，找”来店“！开好店，用“店驰”！
      </p>

      <!--            文化            -->
      <div
        class="text-center container"
        style="font-size: 20px; margin-top: 40px"
      >
        <div class="col-md-3" />
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <span class="col-md-1">文化</span>
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <div class="col-md-3" />
      </div>
      <p class="text-center">客户，团队，专业，勤奋，靠谱</p>

      <!--            创始人            -->
      <div
        class="text-center container"
        style="font-size: 20px; margin-top: 40px"
      >
        <div class="col-md-3" />
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <span class="col-md-1">创始人</span>
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <div class="col-md-3" />
      </div>
      <div class="container">
        <div class="col-md-3" />
        <div class="col-md-3">
          <img
            src="@/assets/img/05.png"
            width="250"
          >
        </div>
        <div class="col-md-6">
          <h3>邱浩（大狙）</h3>
          <h5>创始人&CEO</h5>
          <p>
            曾就职于中国第一家零售信息化服务商海鼎14年，任副总经理6年，连锁便利店市场占有率第一。每年和客户在一起的时间超过250天，对零售业及零售信息化有深刻的洞察与见解。
            邱浩与来店团队的愿景是：“让零售人牛起来！”
          </p>
        </div>
      </div>
      <div
        class="container"
        style="margin-bottom: 10px;"
      >
        <div class="col-md-6">
          <h3 style="text-align: right">徐淼（三丰）</h3>
          <h5 style="text-align: right">联合创始人&CMO</h5>
          <p style="text-align: right">
            拥有12年零售信息化从业经验，平均每年有200余天在客户现场，对零售行业以及零售信息化有深刻的理解。9年在上海海鼎公司任销售经理，全国零售线条销售总监。毕业于浙江大学，获得工商管理学士，企业管理硕士学位。
          </p>
        </div>
        <div class="col-md-3">
          <img
            src="@/assets/img/06.png"
            width="250"
          >
        </div>
        <div class="col-md-3" />
      </div>

      <!--            荣誉资质            -->
      <div
        class="text-center container"
        style="font-size: 20px; margin-top: 40px"
      >
        <div class="col-md-3" />
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <span class="col-md-2">荣誉资质</span>
        <HR
          class="col-md-2"
          width="30%"
          size="5"
          noshade="true"
          color="#999"
        />
        <div class="col-md-3" />
      </div>
      <div class="container">
        <div class="col-md-3" />
        <div class="col-md-3">
          <img
            src="@/assets/img/01.png"
            width="250"
          >
        </div>
        <div class="col-md-3">
          <img
            src="@/assets/img/02.png"
            width="250"
          >
        </div>
        <div class="col-md-3" />
      </div>
      <div
        class="container"
        style="margin-bottom: 20px"
      >
        <div class="col-md-3" />
        <div class="col-md-3">
          <img
            src="@/assets/img/04.png"
            width="250"
          >
        </div>
        <div class="col-md-3">
          <img
            src="@/assets/img/03.png"
            width="250"
          >
        </div>
        <div class="col-md-3" />
      </div>

    </div>
    <div
      v-show="!tabArea"
      class="container"
    >
      <timeline>
        <timeline-title>2020年</timeline-title>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        > 伟大！我们正在一起创造中</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【11月】荣获2020年度长宁区“科技之星”团队称号 </timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【07月】荣获第四届“中国创翼”创业创新大赛长宁赛区优胜奖</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【06月】荣获第九届中国创新创业大赛成长组优秀立项企业</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【05月】店驰2020上线</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【02月】 完成全新“巡店”组件和游戏化、网规组件设计及销售预测算法升级</timeline-item>

        <timeline-title>2019年</timeline-title>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【12月】 累计获得软件著作权20项</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【11月】 与上海著名上市企业“来伊份”开展合作</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【06月】 UI3.0上线（店驰2019）</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【03月】 从纯SaaS模式向项目模式探索，签署第一个项目制客户：新佳宜（服务模式升级）
        </timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【01月】 推出“探照灯”大数据洞察产品</timeline-item>
        <timeline-title>2018年</timeline-title>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【12月】 累计登记软件著作权14项</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【12月】 中国高成长连锁行业“2018最佳服务商”</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【11月】 “店驰2019”（拓店组件）发布上线（H5）</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【11月】 荣获上海市高新技术企业资格认定（三年）</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【10月】 荣获第七届中国创新创业大赛"创业在上海”国际创新创业大赛优胜企业奖</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【06月】 荣获第七届中国创新创业大赛成长组行业总决赛入围奖</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【01月】 推出“战略地图”功能</timeline-item>

        <timeline-title>2017年</timeline-title>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【12月】 中国高成长连锁行业“2017最佳服务商”</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【08月】 8日召开一周年庆典，全国众多嘉宾到场，发布融资信息，发布“店驰”品牌
        </timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【07月】 确定产品品牌与公司名称脱离，注册“店驰”品牌</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【06月】 获得弘章资本旗下风投基金天使轮投资</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【03月】 “来店”（店驰）督导组件v1.0发布</timeline-item>

        <timeline-title>2016年</timeline-title>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【12月】 获得10个天使客户的早期支持</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【11月】 “来店”（店驰）拓店组件v1.0发布</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【08月】 公司正式成立于2016年8月8日 （公司生日/周年庆）</timeline-item>
        <timeline-item
          bg-color="#9dd8e0"
          :hollow="true"
        >【05月】 公司筹备于2016年5月至8月</timeline-item>
      </timeline>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'

export default {
  name: 'CompanyIntroduction',
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle
  },
  data () {
    return {
      tabArea: true
    }
  },
  mounted () {
    const wow = new WOW()
    wow.init()
  },
  methods: {}
}
</script>
<style scoped>
.banner {
  color: #fff;
  height: 180px;
  background: url('../assets/img/banner1.png') rgba(70, 70, 70, 0.59) no-repeat
    center center;
  background-blend-mode: multiply;
  background-size: cover;
}

.banner > p {
  position: relative;
  top: 65px;
  font-size: 30px;
}

p {
  font-size: 14px;
  line-height: 1.75;
  font-weight: normal;
}

.nav {
  margin-bottom: 20px;
  border-top-width: 1px;
  border-bottom-width: 2px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: transparent;
  border-bottom-color: #f5f7fa;
}

.nav .active {
  background: #999;
  color: #fff;
}

.nav > a {
  display: inline-block;
  text-decoration: none;
  width: 120px;
  height: 60px;
  line-height: 60px;
  color: #333;
}

.nav > a:hover {
  color: #1e73be;
  border-color: #1e73be;
}

.news-container {
  overflow: hidden;
  margin-bottom: 0;
}

.news-container > li {
  width: 55.6%;
  height: 120px;
  float: left;
  color: #333;
  text-align: right;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.news-container > li:hover {
  color: #1e73be;
  border: 1px solid #1e73be;
  cursor: pointer;
}

.news-container > li:nth-of-type(2n) {
  float: right;
  text-align: left;
}

.news-container > li > .content {
  width: 20%;
  float: left;
  padding: 20px 0;
}

.news-container > li > .time {
  width: 60%;
  float: left;
  padding: 10px 0;
}

.news-container > li > .time > p {
  /*font-size: 30px;*/
  margin: 5px 0;
}

.news-container > li > .circle {
  width: 20%;
  height: 100%;
  float: left;
  position: relative;
}

.news-container > li > .circle > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}

.news-container > li > .circle > i {
  display: block;
  width: 1px;
  height: 100%;
  background: #707070;
}

.news-container > li:nth-of-type(2n) > .content {
  float: right;
}

.news-container > li:nth-of-type(2n) > .time {
  float: right;
}

.news-container > li:nth-of-type(2n) > .circle {
  float: right;
}

.news-container > li:nth-of-type(1) > .circle > i {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.more {
  font-size: 25px;
  color: #707070;
}

.more > i {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .banner {
    height: 225px;
    line-height: normal;
  }

  .banner > p {
    font-size: 20px;
    top: 102px;
    position: relative;
    font-weight: bold;
  }

  .news-container > li {
    width: 100%;
  }

  .news-container > li > .content {
    width: 30%;
    text-align: left;
    float: right;
  }

  .news-container > li > .time {
    width: 70%;
    text-align: left;
    float: right;
  }

  .news-container > li > .circle {
    display: none;
  }
}
</style>
